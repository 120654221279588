import { createSSRApp } from "vue";
import { createBootstrap } from "bootstrap-vue-next";
import { createStore } from "vuex";
import mainRegisters from "@/store";
import createRouter from "@storefront/core/router";
import httpClient from "@storefront/core/plugins/httpClient";
import config from "@config";
import { sync } from "vuex-router-sync";
import VueLazyload from "vue3-lazyload";
import { createHead, setHeadInjectionHandler } from "@unhead/vue";
import { i18n } from "@storefront/core/i18n";
import { createGtm } from "@gtm-support/vue-gtm";

import "@/registerServiceWorker";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { ID_INJECTION_KEY } from "element-plus";
import { ZINDEX_INJECTION_KEY } from "element-plus";

// Add the necessary CSS
import "vue3-carousel/dist/carousel.css";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

dom.watch();
library.add(fab);
library.add(fas);

const currentTemplate = config.template.name;
import(`./${currentTemplate}/scss/main.scss`);

// SSR requires a fresh app instance per request, therefore we export a function
// that creates a fresh app instance. If using Vuex, we'd also be creating a
// fresh store here.
export async function createApp(req = {}) {
  const { default: App } = await import(`./${currentTemplate}/App.vue`);

  const app = createSSRApp(App);

  const debug = import.meta.env.PROD;
  const store = createStore({
    modules: {},
    strict: debug,
  });
  const registers = await mainRegisters;
  registers.forEach((f) => f(app, store));

  const router = await createRouter(store);

  sync(store, router);

  const head = createHead();
  setHeadInjectionHandler(() => head);

  app
    .use(router)
    .use(store)
    .use(i18n)
    .use(VueLazyload)
    .use(createBootstrap())
    .use(
      createGtm({
        id: config.gtmId,
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false,
        enabled: false,
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router,
      })
    );

  app.component("font-awesome-icon", FontAwesomeIcon);
  app.config.globalProperties.productionTip = false;

  app.provide(ID_INJECTION_KEY, {
    prefix: 1024,
    current: 0,
  });
  app.provide(ZINDEX_INJECTION_KEY, { current: 0 });

  // Inject httpClient eveywhere using app-level provide
  app.provide("http", httpClient(req));
  return { app, router, head, store };
}
