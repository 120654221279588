import config from "@config";
import { Logger } from "../lib/logger";

/**
 * running on server (true or false)
 */
export const isServer = typeof window === "undefined";

export default {
  /**
   *
   * @param {float|int} amount
   * @param {boolean} showCurrencySign
   * @returns {string}
   */
  formatCurrency(amount, showCurrencySign = true) {
    let formatter;
    if (showCurrencySign) {
      formatter = new Intl.NumberFormat(config.currency.locale, {
        style: "currency",
        currency: config.currency.currency,
      });
    } else {
      formatter = new Intl.NumberFormat(config.currency.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return config.currency.useDash
        ? formatter.format(amount).replace(/,00$/, ",-")
        : config.currency.useZeros == false ? formatter.format(amount).replace(/,00$/, "") : formatter.format(amount);
    }
    return config.currency.useDash
      ? formatter.format(amount).replace(/,00$/, ",-")
      : config.currency.useZeros == false ? formatter.format(amount).replace(/,00$/, "") : formatter.format(amount);
  },
};
/**
 * @param {string} attribute
 * @param {mixed} value
 *
 * @return {string}
 */
export function slugify(attribute, value) {
  const regExp = /[^0-9a-zA-Z]+/gm;
  attribute.replace(regExp, "-").toLowerCase();
  if (typeof value == "string") {
    value = value.replace(regExp, "-").toLowerCase();
  } else if (Array.isArray(value)) {
    value.forEach((val, index) => {
      value[index] = val.replace(regExp, "-").toLowerCase();
    });
  } else {
    Logger.debug("slugify", "helper", value)();
  }
  return attribute + ":" + value;
}

/**
 * @param {object} attribute
 *
 * @return {object}
 */
export function slugifyAggregation(attribute) {
  attribute?.options?.forEach((option, index) => {
    option.label = slugify(attribute.attribute_code, option.label).split(":")[1];
    attribute.options[index] = option;
  });
  return attribute;
}

export function createToast(options) {
  if (!isServer) {
    const template = document.createElement("template");
    const id = "toast" + Date.now();
    const selector = `#${id}`;
    template.innerHTML = `
          <div
              id="${id}"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              class="c-toast c-toast-solid c-toast-append c-toast-${options.type == "success" ? "success" : "danger"
      }"
          >
              <div tabindex="0" class="toast show">
              <header class="toast-header toast-title">
                  <strong class="mr-2">${options.title || ""}</strong>
                  <button type="button" aria-label="Close" class="close ms-auto mb-1" onclick="document.querySelector('${selector}').remove()">×</button>
              </header>
              <div class="toast-body">${options.text}</div>
              </div>
          </div>
        `;
    const node = template.content.firstElementChild;
    document.querySelector("#c-toaster-slot").appendChild(node);

    setTimeout(() => {
      document.querySelector(`${selector}`)?.remove();
    }, 5000);
  }
}
