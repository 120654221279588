/**
 * This plugin-type create an axios HTTP client to do request.
 * It handles tokens too to acess to private routes on API.
 */

import axios from "axios";
import { Logger } from "@storefront/core/lib/logger";

export default function (req = {}) {
  const apiUrl = import.meta.env.API_URL || "http://localhost:8080";

  // Create axios client
  const httpClient = axios.create({
    // Change API url: depends on server side or client side
    baseURL: import.meta.env.SSR ? apiUrl : "/",
  });

  // Use request interceptors
  httpClient.interceptors.request.use(async (config) => {
    let token = null;

    // Get current token in cookies
    if (import.meta.env.SSR) {
      if (req.cookies != null) {
        token = req.cookies.token;
      }
    } else {
      token = (await import("js-cookie")).default.get("token");
    }

    // If token: add header
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  });

  // Use response interceptor
  httpClient.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response) {
        const { data } = err.response;
        // Catch error and use UVue error handler plugin to display it
        return Logger.error(data.error || "Oups!", err.response.status)();
      }
      return Promise.reject(err);
    }
  );

  return httpClient;
}
