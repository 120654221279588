import config from "@config";
// import store from "@/store";
import { slugifyAggregation } from "@storefront/core/helpers";

export function cleanPath(to) {
  let path = to.path;
  let split = to.path.split("/");
  if (config.languages.available.includes(split[1])) {
    if (split.length > 1) {
      split = split.splice(2);
      path = "/" + split.join("/");
    }
  }
  return path;
}

// Replace any occurrences of "//" with "/"
export function cleanFullPath(to) {
  let fullpath = to.fullPath;
  fullpath = fullpath.replace(/\/\//g, "/");
  return fullpath;
}

export async function extractFilters(to, newFilters = [], store) {
  const routeQuery = to.query;
  let aggregations = {};
  await store.dispatch("category/loadAggregations", { category_id: newFilters.category });
  delete newFilters.category;
  aggregations = store.getters["category/getAggregations"];

  const allFilters = {};
  if (routeQuery["sort"]) allFilters.sort = routeQuery.sort;
  if (routeQuery["page"]) allFilters.page = routeQuery.page;
  if (routeQuery["page-size"]) allFilters.pageSize = routeQuery["page-size"];

  const filters = {};
  newFilters.forEach((filter) => {
    let filterArray = [];
    filterArray = filter.split(":");
    filters[filterArray[0]] = filterArray[1];
  });
  for (const filter in filters) {
    aggregations.forEach((aggregation) => {
      if (aggregation.attribute_code == filter) {
        const newAggregation = slugifyAggregation(aggregation);
        if (filters[filter].indexOf(",") === -1) {
          const foundValue = newAggregation.options.find((el) => el.label == filters[filter]);
          if (foundValue) {
            filters[filter] = foundValue.value;
          }
        } else {
          let filterValues = filters[filter].split(",");
          for (let i = 0, len = filterValues.length; i < len; i++) {
            const foundValue = newAggregation.options.find((el) => el.label == filterValues[i]);
            if (foundValue) {
              filterValues[i] = foundValue.value;
            }
          }
          filters[filter] = filterValues.join(",");
        }
      }
    });
  }

  Object.keys(routeQuery).forEach((element) => {
    if (element.startsWith("filter-")) {
      const attribute_code = element.replace("filter-", "");
      filters[attribute_code] = routeQuery[element];
    }
  });

  if (Object.keys(filters).length) allFilters["filters"] = filters;
  return Object.keys(allFilters).length ? allFilters : false;
}

export function extractSearchFilters(to) {
  const routeQuery = to.query;

  const allFilters = {};
  if (routeQuery["sort"]) allFilters.sort = routeQuery.sort;
  if (routeQuery["page"]) allFilters.page = routeQuery.page;
  if (routeQuery["page-size"]) allFilters.pageSize = routeQuery["page-size"];

  const filters = {};

  Object.keys(routeQuery).forEach((element) => {
    if (element.startsWith("filter-")) {
      const attribute_code = element.replace("filter-", "");
      filters[attribute_code] = routeQuery[element];
    }
  });
  if (Object.keys(filters).length) allFilters["filters"] = filters;

  return Object.keys(allFilters).length ? allFilters : false;
}