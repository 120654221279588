// import Vue from "vue";
import { createRouter, createWebHistory, createMemoryHistory } from "vue-router";
import getRoutes from "@/router";
//import { VueRouterEx } from "@storefront/core/lib/router";
import { beforeEachGuard } from "@storefront/core/router/beforeEach.js";
import { getCurrentLanguage } from "../i18n";
import { cleanPath } from "./helpers/cleanPath";
//import {Logger} from "@storefront/core/lib/logger";
import config from "@config";

// Vue.use(VueRouter);

export default async (store) => {
  const router = createRouter({
    // use appropriate history implementation for server/client
    // import.meta.env.SSR is injected by Vite.
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes: await getRoutes(),
    scrollBehavior(to, from, savedPosition) {
      if (to.name.startsWith("category-nl")) {
        if (from.name?.startsWith("category-nl")) {
          if (to.name == from.name && from.meta != "top") return savedPosition;
          else return { top: 0 };
        } else {
          return savedPosition;
        }
      } else return { top: 0 };
    },
  });

  router.beforeEach(async (to, from, next) => {
    let toRoute = to;
    if (
      getCurrentLanguage() != config.languages.default &&
      to.path.split("/")[1] != getCurrentLanguage()
    ) {
      toRoute = {
        ...toRoute,
        path: "/" + getCurrentLanguage() + (to.path === "/" ? "" : to.path),
        fullPath: "/" + getCurrentLanguage() + (to.fullPath === "/" ? "" : to.fullPath),
      };
    }
    return await beforeEachGuard(toRoute, from, next, router, store);
  });

  router.afterEach(async (to, from) => {
    if (
      cleanPath(from) != cleanPath(to) &&
      to.path.split("/")[1] != getCurrentLanguage() &&
      getCurrentLanguage() != config.languages.default
    ) {
      router.push("/" + getCurrentLanguage() + to.fullPath);
    }
  });

  return router;
};
