import { createApp } from "./main";

createApp().then(({ app, router, store }) => {
  // wait until router is ready before mounting to ensure hydration match
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  }
  router.isReady().then(() => {
    app.mount("#app");
  });
});
