/* eslint-disable no-console */

import { Logger } from "@storefront/core/lib/logger";
import { register } from "register-service-worker";

if (import.meta.env.PROD && !import.meta.env.SSR) {
  register(`${import.meta.env.BASE_URL}sw.js`, {
    ready() {
      Logger.debug(
        "App is being served from cache by a service worker.\n" +
        "For more details, visit https://goo.gl/AFskqB"
      )();
    },
    registered() {
      Logger.debug("Service worker has been registered.")();
    },
    cached() {
      Logger.debug("Content has been cached for offline use.")();
    },
    updatefound() {
      Logger.debug("New content is downloading.")();
    },
    updated() {
      Logger.debug("New content is available; please refresh.")();
    },
    offline() {
      Logger.debug("No internet connection found. App is running in offline mode.")();
    },
    error(error) {
      Logger.error("Error during service worker registration: " + error)();
    },
  });
}
